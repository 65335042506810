.background-image-a {
  background-image: url('./images/art.jpg');
  background-size: cover;
}

.card-background-social {
  background-image: url('./images/chill.jpg');
}

.card-background-shop {
  background-image: url('./images/shop.jpg');
}