@mixin navbar-collapse() {
    .navbar-collapse{
        position: fixed;
        display: block;
        top: 0;
        height: 100% !important;
        width: 300px;
        right: 0;
        z-index: 1032;
        visibility: visible;
        background-color: #999;
        overflow-y: visible;
        border-top: none;
        text-align: left;

        max-height: none !important;

        @include transform-translate-x(300px);
        @include transition (0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));

        &:after{
            @include icon-gradient($default-color);
            @include opacity(.76);

            display: block;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
        }

        &[data-color="blue"]:after{
            @include icon-gradient($info-color);
        }
        &[data-color="green"]:after{
            @include icon-gradient($success-color);
        }
        &[data-color="orange"]:after{
            @include icon-gradient($primary-color);
        }
        &[data-color="red"]:after{
            @include icon-gradient($danger-color);
        }
        &[data-color="yellow"]:after{
            @include icon-gradient($warning-color);
        }

        .nav-open &{
            @include transform-translate-x(0px);
        }

        .navbar-nav:not(.navbar-logo){
            .nav-link {
                margin: 0 1rem;
                margin-top: 0.3125rem;

                &:not(.btn){
                    color: $white-color !important;
                }
            }
        }

        .dropdown-menu .dropdown-item{
            color: $white-color;
        }

        .menu-on-left &{
            right: auto;
            left: 0;
            @include transform-translate-x(-300px);
        }
    }

    .menu-on-left .navbar-brand{
        float: right;
        margin-right: 0;
        margin-left: 1rem;
    }

    .navbar{
        .navbar-brand{
            padding-top: .5rem;
            padding-bottom: .5rem;
        }

        .navbar-nav{
            margin-top: 53px;

            &.navbar-logo{
                top: 0;
                height: 53px;
            }
        }

        .dropdown.show .dropdown-menu,
        .dropdown .dropdown-menu{
            background-color: transparent;
            border: 0;
            transition: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            transform: none !important;
            width: auto;
            margin: 0 1rem;
            margin-bottom: 15px;
            padding-top: 0;
            height: 300px;
            overflow-y: scroll;

            &:before{
                display: none;
            }
        }

        .dropdown{
            .dropdown-item{
                padding-left: 2.5rem;
            }

            .dropdown-menu{
               display: none;
           }

           &.show .dropdown-menu{
               display: block;
           }
        }

        .dropdown-menu .dropdown-item:focus,
        .dropdown-menu .dropdown-item:hover{
            color: $white-color;
            border-radius: $border-radius-small;
        }

        .navbar-translate{
            width: 100%;
            position: relative;
            display: flex;
            -ms-flex-pack: justify !important;
            justify-content: space-between !important;
            -ms-flex-align: center;
            align-items: center;
            @include transition (0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));
        }

        .navbar-toggler-bar{
            display: block;
            position: relative;
            width: 22px;
            height: 1px;
            border-radius: 1px;
            background: $white-bg;

            & + .navbar-toggler-bar{
                margin-top: 7px;
            }

            &.bar2{
                width: 17px;
                transition: width .2s linear;
            }
        }

        &.bg-white:not(.navbar-transparent) .navbar-toggler-bar{
            background: $default-color;
        }

        & .toggled .navbar-toggler-bar{
            width: 24px;

            & + .navbar-toggler-bar{
                margin-top: 5px;
            }
        }

        .nav-link{
            i.fa,
            i.now-ui-icons{
                opacity: .5;
            }
        }
    }

    .nav-open{
        .wrapper{
            @include transform-translate-x(-150px);
        }

        .navbar-translate{
            @include transform-translate-x(-300px);
        }

        .menu-on-left{
            .navbar-collapse{
                @include transform-translate-x(0px);
            }

            .navbar-translate{
                @include transform-translate-x(300px);
            }

            .wrapper{
                @include transform-translate-x(150px);
            }

            #bodyClick{
                right: auto;
                left: 300px;
            }
        }
    }

    .wrapper{
        @include transition (0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    }

    .bar1,
    .bar2,
    .bar3 {
      outline: 1px solid transparent;
    }
    .bar1 {
      top: 0px;
      @include bar-animation($topbar-back);
    }
    .bar2 {
      opacity: 1;
    }
    .bar3 {
      bottom: 0px;
      @include bar-animation($bottombar-back);
    }
    .toggled .bar1 {
      top: 6px;
      @include bar-animation($topbar-x);
    }
    .toggled .bar2 {
      opacity: 0;
    }
    .toggled .bar3 {
      bottom: 6px;
      @include bar-animation($bottombar-x);
    }

    @include topbar-x-rotation();
    @include topbar-back-rotation();
    @include bottombar-x-rotation();
    @include bottombar-back-rotation();

    @-webkit-keyframes fadeIn {
      0% {opacity: 0;}
      100% {opacity: 1;}
    }
    @-moz-keyframes fadeIn {
      0% {opacity: 0;}
      100% {opacity: 1;}
    }
    @keyframes fadeIn {
      0% {opacity: 0;}
      100% {opacity: 1;}
    }

    #bodyClick{
        height: 100%;
        width: 100%;
        position: fixed;
        opacity: 1;
        top: 0;
        left: auto;
        right: 300px;
        content: "";
        z-index: 9999;
        overflow-x: hidden;
        background-color: transparent;
        @include transition (0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    }

}
